import gql from 'graphql-tag'
import candidateFragment from './CandidateFragment'

export default gql`{
  id
  candidate {
    ...${candidateFragment}
  }
  email
  name
  office
  photo
  type
  telephone
  role {
    id
    name
  }
}
`